import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  button {
    width: 100%;
    height: 25px;
    color: white;
    border: none;
    background-color: ${Theme.primary};
    cursor: pointer;
  }

  p {
    padding: 10px;
  }
  div.text {
    position: absolute;
    top: 0;
    width: 250px;
    color: white;
    background-color: #303030;
    opacity: 0.7;
    :hover {
      opacity: 1;
    }
  }
  ul {
    display: none !important;
  }
  img {
    max-height: 500px;
    width: 100%;
    height: 100%;
    object-fit: cover; /* ou 'contain' dependendo do efeito desejado */
  }
  video {
    max-height: 1000px;
    width: 100%;
    height: 100%;
    align-self: center;
    margin: 0 auto;
  }
`;
export const SubContainer = styled.div`
  display: grid;
  margin-top: 15px;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (min-width: 500px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 1700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  img {
    border-radius: 16px;
    height: auto; 
    width: 150px;
    object-fit: cover; /* ou 'contain' dependendo do efeito desejado */
  }
  div.space {
    display: flex;
    max-height: 100px;
    gap: 15px;
    padding: 10px;
    background-color: ${Theme['secondary-light']};
  }
  div.legend {
    height: 100%;
    overflow: auto;
    cursor: pointer;
  }
`;
export const FixendCompo = styled.div`
position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  border: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5cc;
`
export const FixedContainer = styled.div`
  
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  background-color: #f5f5f4;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  margin-top: 150px;
  border-radius: 10px;
  height: 500px;
  img{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover; /* ou 'contain' dependendo do efeito desejado */
  }
  p, small, label{
    margin: 0;
    padding: 0;
  }
  .container-message{
    .close {
      align-self: end;
      margin: 0;
      color: #336699;
      padding: 0;
      background-color: none;
      border: none;
      position: relative;
      right:0;
      z-index: 50000;
    }
    max-height:600px ;
    background-color: #fcfcfc;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const CommmentsContainer = styled.div`

  .list {
    display: flex;
  }

 
`;

export const ChatLog = styled.div`
  padding: 0 10px;
  margin: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0; /* Esconde a barra de rolagem do navegador Chrome/Safari */
  }

  word-wrap: break-word;
  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ChatMessage = styled.div`
  display: flex;
  word-wrap: break-word;
  width: 100%;
  margin: 10px;
  .active {
    margin: 0 0 0 auto;
    div.msg {
      background-color: ${(props) =>
        props.role === 'active' ? `${Theme.primary}` : '#fff'};
      color: ${Theme.secondary};
      flex-direction: row-reverse;
    }
  }

  div {
    display: flex;
    word-wrap: break-word;
    flex-direction: ${(props) =>
      props.role === 'active' ? 'initial' : 'row-reverse'};
    div.msg {
      display: flex;
      width: 100%;
      margin: 0 0;
      word-wrap: break-word;
      max-width: max-content;
      background-color: ${Theme.secondary};
      padding: 6px;
      border-radius: ${(props) =>
        props.role === 'active'
          ? '10px 10px 0 10px'
          : props.role === 'activeT'
          ? '0'
          : '10px 10px 10px 0px'};
    }
    img {
      max-width: 300px;
      max-height: 250px;
    }
    img.logo-img {
      width: 45px;
      height: 45px;
      padding: 2px;
      border-radius: 50%;
      border: 2px solid ${Theme.secondary};
    }
  }
  .activeT {
    margin: 0 auto;
    padding: 2px;
    div {
      background-color: white;
      color: ${Theme.primary};
    }
  }
`;

export const UserInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
`;

export const TextInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 5px;
`;

export const SendButton = styled.button`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 7px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const CloseButton = styled.button`
  padding: 8px 16px;
  background-color: red;
  color: #fff;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const ChatSubContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 500px;
  height: 100%;
  @media (max-width: 600px) {
    height: 100%;
    max-height: calc(90vh - 100px);
    min-height: calc(90vh - 100px);
  }
  div.log-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  div.history-message {
    background-color: #f5f5f5;
    width: 185px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 502px;

    transition: 1s;
    button {
      margin: 10px 105px;
      width: 90%;
      height: max-content;
      text-overflow: clip;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      border: none;

      border-radius: 9px;
      background-color: white;
      color: ${Theme.primary};
      :last-of-type {
        margin-bottom: 12px;
      }
    }
  }
`;