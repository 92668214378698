import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from '../../services/axios';
import { useCookies } from 'react-cookie';
import Loader from '../Loader';
import { PreviewContent } from './styled';

const LatestNews: React.FC = () => {
  const [imageFile, setImageFile] = useState({
    selectedFile: '',
    type: ''
  });
  const nav = useHistory();
  const [imageName, setImageName] = useState('');
  const [title, setTitle] = useState<string>('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [prevLegend, setPrevLegend] = useState('');
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [myPreview, setMyPreview] = useState<boolean>(false);

  async function createAnnouncement() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );
    const data = {
      video: (imageFile.type === 'video/mp3' || imageFile.type === 'video/mp4' ) ? imageFile :  "",
      image: (imageFile.type !== 'video/mp3' && imageFile.type !== 'video/mp4' ) ? imageFile : "",
      prev_legend: prevLegend,
      full_legend: htmlDescription,
      title: title,
      idea: false
    };
    axios
      .post('/api/v1/latest-news/create', data, {timeout: 0})
      .then((res) => {
        setTitle('');
        setPrevLegend('');
        setImageName('');
        setImageFile({
          selectedFile: '',
          type: ''
        });
        setFullLegend('');
        setConfirmation(false);
        alert('Latest News created');
        nav.push('/announcements');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fileSelect(props: any) {
    if (props[0] === undefined) {
      setImageName('');
      setImageFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    setImageName(props[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };
  }

  window.onclick = function (event: any) {
    if (event.target.className === 'modal') {
      setConfirmation(false);
    }
  };

  const myModal = (
    <div
      id='myModal'
      style={{ display: confirmation ? 'block' : 'none' }}
      className='modal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <span onClick={() => setConfirmation(false)} className='close'>
            &times;
          </span>
          <h2>
            You are about to publish a new Latest News. This will go to the Home
            Page and Latest News feed for all users to view.
          </h2>
        </div>
        <div className='modal-confirmation'>
          <form>
            {modalLoading ? (
              <Loader />
            ) : (
              <>
                <button
                  type='button'
                  onClick={createAnnouncement}
                  className='cancelbtn'
                >
                  Yes
                </button>
                <button
                  type='button'
                  onClick={() => setConfirmation(false)}
                  className='deletebtn'
                >
                  No
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );

  const preview = (
    <PreviewContent
      style={{ display: myPreview ? 'block' : 'none', maxWidth: '100%' }}
    >
      <span onClick={() => setMyPreview(false)} className='close'>
        &times;
      </span>
      <h1>Title:</h1>
      <h1 className='primary'>{title}</h1>
      <hr />
      <h1>Preview:</h1>
      <h1 className='primary'>{prevLegend}</h1>
      <hr />
      <h2>Full Article: </h2>
      {fullLegend && (
        <div
          dangerouslySetInnerHTML={{
            __html: draftToHtml(convertToRaw(fullLegend.getCurrentContent()))
          }}
        ></div>
      )}
    </PreviewContent>
  );

  return (
    <div className='new-announcement'>
      <h1>FEG Latest News</h1>
      <div className='utils'>
        <h4>Title</h4>
        <input
          type='text'
          name='nameInput'
          value={title}
          maxLength={250}
          onChange={(e) => setTitle(e.target.value)}
        />
        <h4>Preview Field</h4>
        <input
          type='text'
          name='nameInput'
          value={prevLegend}
          maxLength={250}
          onChange={(e) => setPrevLegend(e.target.value)}
        />
        <h4>Picture or Video</h4>
        <div className='imageInput'>
          <label
            style={{
              display:
                imageName.length <= 0 || imageName === undefined ? '' : 'none'
            }}
            htmlFor='inputFileImg'
          >
            +
          </label>
          <label
            style={{
              display:
                imageName.length >= 1 && imageName !== undefined ? '' : 'none',
              padding: '7px 10px'
            }}
            htmlFor='inputFileImg'
          >
            {imageName}
          </label>
          <input
            id='inputFileImg'
            className='typefile'
            type='file'
            accept='.jpg,.png,.mp4,.mp3'
            onChange={(event) => fileSelect(event.target.files)}
          />
          <p>images less than 4 mb only</p>
        </div>
        <h4>Full Article</h4>

        <Editor
          editorState={fullLegend}
          wrapperClassName='demo-wrapper'
          stripPastedStyles={true}
          editorClassName='demo-editor'
          onEditorStateChange={(e) => {
            setFullLegend(e);
          }}
        />
      </div>

      <button className='button' onClick={() => setConfirmation(true)}>
        Publish
      </button>
      {myModal}
      {preview}
      <button
        onClick={() => {
          setMyPreview(myPreview ? false : true);
        }}
      >
        Preview Content
      </button>
    </div>
  );
};

export default LatestNews;
