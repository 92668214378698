//Components with modal for latest news
import React, { useState } from 'react';
import * as S from './styled';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from '../../services/axios';
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { Theme } from 'src/styles/Theme';

export default function IdeaLatestNews({close}: any) {
  const [imageFile, setImageFile] = useState({
    selectedFile: '',
    type: ''
  });
  const nav = useHistory();
  const [imageName, setImageName] = useState('');
  const [title, setTitle] = useState<string>('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [prevLegend, setPrevLegend] = useState('');
  const [confirmation, setConfirmation] = useState<boolean>(false);

  function fileSelect(props: any) {
    if (props[0] === undefined) {
      setImageName('');
      setImageFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    setImageName(props[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };
  }
  const customerId = localStorage.getItem('id');

  async function createAnnouncement() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );
    const data = {
      video: (imageFile.type === 'video/mp3' || imageFile.type === 'video/mp4' ) ? imageFile :  "",
      image: (imageFile.type !== 'video/mp3' && imageFile.type !== 'video/mp4' ) ? imageFile : "",
      prev_legend: prevLegend,
      full_legend: htmlDescription,
      title: title,
      customer_id: customerId,
      idea: true
    };
    console.log(data);
    axios
      .post('/api/v1/latest-news/create', data, {timeout: 0})
      .then((res) => {
        setTitle('');
        setPrevLegend('');
        setImageName('');
        setImageFile({
          selectedFile: '',
          type: ''
        });
        setFullLegend('');
        setConfirmation(false);
        alert('Latest News created');
        nav.push('/announcements');
      })
      .catch((err) => {
        console.log(err);
      });
      
  }

  return (
    <S.Container>
      <S.header>
        <S.Title>Latest News - Idea Submission</S.Title>
        <S.Icon onClick={() => close()}><AiOutlineFullscreenExit color={Theme.primary} size={40}/></S.Icon>
      </S.header>

      <S.Form>
        
     
        <h4>Title</h4>
        <input
          type='text'
          name='nameInput'
          value={title}
          maxLength={250}
          onChange={(e) => setTitle(e.target.value)}
        />
        <h4>Preview Field</h4>
        <input
          type='text'
          name='nameInput'
          value={prevLegend}
          maxLength={250}
          onChange={(e) => setPrevLegend(e.target.value)}
        />
         
        <h4>Picture or Video</h4>
        <div className='imageInput'>
          <label
            style={{
              display:
                imageName.length <= 0 || imageName === undefined ? '' : 'none'
            }}
            htmlFor='inputFileImg'
          >
            +
          </label>
          <label
            style={{
              display:
                imageName.length >= 1 && imageName !== undefined ? '' : 'none',
              padding: '7px 10px'
            }}
            htmlFor='inputFileImg'
          >
            {imageName}
          </label>
          <input
            id='inputFileImg'
            className='typefile'
            type='file'
            style={{backgroundColor: 'transparent'}}
            accept='.jpg,.png,.mp4,.mp3'
            onChange={(event) => fileSelect(event.target.files)}
          />
          <p>images less than 4 mb only</p>
        </div>
        <h4>Full Article</h4>

        <Editor
          editorState={fullLegend}
          wrapperClassName='demo-wrapper'
          stripPastedStyles={true}
          editorClassName='demo-editor'
          onEditorStateChange={(e) => {
            setFullLegend(e);
          }}
        />
          <S.Button className='button' onClick={() => createAnnouncement()}>
        Publish
      </S.Button>
      </S.Form>

    
    </S.Container>
  )
}