import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import axios from 'src/services/axios';
import { ChatLog, ChatMessage, ChatSubContainer, CommmentsContainer, Container, FixedContainer, FixendCompo, SubContainer } from './styled';
import { useHistory } from 'react-router-dom';
import { Avatar, Input } from '@material-ui/core';
import { data } from './data';
import { IoMdClose } from 'react-icons/io';

interface PropsImages {
  title: string;
  prev_legend: string;
  image_url: string;
}
interface Item{
  id:string;
  active:boolean;
  createdAt:string;
  title:string;
  prev_legend:string;
  full_legend:string;
  video_url :string;
  image_url:string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CarouselCompoponent = (): React.ReactElement => {
  //const image = localStorage.getItem('image_url');
  const [images, setImages] = useState<any>([]);
  const history = useHistory();
  const [open, setOpen] = useState(false)
  function getImages() {
    axios.get('/api/v1/latest-news').then((res) => {
      const filterImages = res.data.filter(
        (image: any) => image.active === true
      );

      setImages(filterImages);
    });
  }
  const sortItemsByActiveAndDate = (items: Item[]): Item[] => {
    return items.sort((a, b) => {
      // Primeiro, comparar a propriedade ativo
      if (a.active === b.active) {
        // Se ambos têm o mesmo valor de ativo, comparar pela data createdAt em ordem decrescente
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      // Colocar os itens com ativo: true antes dos itens com ativo: false
      return a.active ? -1 : 1;
    });
  };

  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <Carousel
        onChange={(index, item) => console.log(index, item)}
        onClickItem={() => history.push('/announcements')}
        showThumbs={false}
      >
        {images &&
          sortItemsByActiveAndDate(images).map((image: any, index: number) => {
            if (image.active === false) {
              return <></>;
            }
            return (
              <Container key={index}>
                   {image.image_url ? <img alt='' src={image.image_url} />: <video src={image.video_url} controls={true} autoPlay ></video>}
                <div className='text'>
                  <p>{image.prev_legend}</p>
                  <button>Read More</button>
                </div>
              </Container>
            );
          })}
       
      </Carousel>
     
      <SubContainer>
        {images &&
          images.map((image: any) => {
            if (image.active === false) {
              return <></>;
            }
            return (
              <div className='space' key={Math.random()}>
                {image.image_url ? <img alt='' src={image.image_url} />: <video style={{width: 80}} src={image.video_url} loop controls={true} autoPlay ></video>}
                <div
                  className='legend'
                  onClick={() => history.push('/announcements')}
                >
                  <p>{image.prev_legend}</p>
                  <a href=''>Read More</a>
                </div>
              </div>
            );
          })}
      </SubContainer>
    </>
  );
};
