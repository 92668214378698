import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  withRouter
} from 'react-router-dom';
import * as ROUTES from './routerList';

import Home from '../../pages/Home';
import UserValidation from '../../pages/UserValidation';
import Profile from '../../pages/UserValidation/Profile';
import Announcements from '../../pages/Announcements';
import Management from '../../pages/UsersManagement';
import NewAnnouncement from '../../pages/NewAnnouncement';
import CompanyAnnouncement from '../../pages/CompanyAnnouncement';
import Calendar from '../../pages/CompanyCalendar';
import Analytics from '../../pages/Analytics';
import ShortcutManagement from '../../pages/ShortcutManagement';
import Permission from '../../pages/Permission';
import ReactGA from 'react-ga';
import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import PaylocityAnnouncement from '../../pages/PaylocityAnnouncement';
import BenefitsAnnouncement from '../../pages/BenefitsAnnouncement';
import SpecialEvent from 'src/pages/SpecialEvent';
import LocationManagement from 'src/pages/LocationManagement';
import MyProfile from 'src/pages/MyProfile';
import MapJobtitle from 'src/components/MapJobtitle';
import MiniDrawer from 'src/components/MuiSidebar';
import useWindowWith from 'src/utils/width';
import ChatBot from 'src/pages/ChatBot';
import ChatBotSettings from 'src/pages/ChatBotSettings';
import SettingsChatBot from 'src/pages/SettingsChatBot';
import TableCategories from 'src/components/RepositorySettings';
import Chat from 'src/components/Chat';
import NewHome from 'src/pages/NewHome';
import Shortcuts from 'src/pages/Shortcuts';
import UserLocationChat from 'src/pages/UserLocationChat';
import LogsAskFEG from 'src/pages/LogsAskFEG';
import { useCookies } from 'react-cookie';

const PrivateRoutes: React.FC = () => {
  const width = useWindowWith();
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [cookies, , removeCookie] = useCookies(['token']);
  const redirect: any = cookies.redirectLocation;
  const [sidebarOpen, setSidebarOpen] = useState(false);


  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    ReactGA.initialize('UA-189380132-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    if (redirect) {
      const redirectFiltered = decodeURIComponent(redirect).replace(
        /%2F/g,
        '/'
      );
      removeCookie('redirectLocation');
      if (currentPath !== redirectFiltered) {
        removeCookie('redirectLocation');
        console.log(location.pathname);
        if (
          redirectFiltered !== '/' &&
          redirectFiltered !== '/logout' &&
          redirectFiltered !== '/locations/chat/0'
        ) {
          //window.location.href = redirectFiltered;
          return;
        }
      }
    }
  }, []);

  return (
    <BrowserRouter>
      {width > 700 && (
        <>
          <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Chat />
          <Switch>
            <Route
              path={ROUTES.ANNOUNCEMENTPAYLOCITY}
              component={PaylocityAnnouncement}
            />
            <Route
              exact
              path={ROUTES.USERLOCATIONSCHAT}
              component={UserLocationChat}
            />

            <Route path={ROUTES.SHORTCUTS} component={Shortcuts} />
            <Route
              path={ROUTES.ANNOUNCEMENTBENEFITS}
              component={BenefitsAnnouncement}
            />
            <Route
              path={ROUTES.ANNOUNCEMENTBENEFITSALL}
              component={BenefitsAnnouncement}
            />
            <Route exact path={ROUTES.MAPJOBTITLE} component={MapJobtitle} />
            <Route exact path={ROUTES.HOMENEWDESIGN} component={NewHome} />
            <Route exact path={ROUTES.LOGCASKFEG} component={LogsAskFEG} />
            <Route exact path={ROUTES.MYPROFILE} component={MyProfile} />
            <Route path={ROUTES.SPECIALEVENT} component={SpecialEvent} />
            <Route exact path={ROUTES.NEWHOME} component={NewHome} />
            <Route exact path={ROUTES.HOME} component={NewHome} />
            <Route exact path={ROUTES.USER} component={UserValidation} />
            <Route exact path={ROUTES.PROFILE} component={Profile} />
            <Route
              exact
              path={ROUTES.ANNOUNCEMENTS}
              component={Announcements}
            />
            <Route
              exact
              path={ROUTES.SHORTCUTMANAGEMENT}
              component={ShortcutManagement}
            />
            <Route
              exact
              path={ROUTES.LOCATIONMANAGEMENT}
              component={LocationManagement}
            />
            <Route
              exact
              path={ROUTES.NEWANNOUNCEMENT}
              component={NewAnnouncement}
            />
            <Route
              path={ROUTES.ANNOUNCEMENTCOMPANY}
              component={CompanyAnnouncement}
            />
            <Route
              path={ROUTES.ANNOUNCEMENTLOCAL}
              component={CompanyAnnouncement}
            />
            <Route
              path={ROUTES.ANNOUNCEMENTGROUP}
              component={CompanyAnnouncement}
            />
            <Route
              path={ROUTES.ANNOUNCEMENTOTHERS}
              component={CompanyAnnouncement}
            />
            <Route
              exact
              path={ROUTES.SETTINGSCHATBOT}
              component={ChatBotSettings}
            />
            <Route path={ROUTES.MANAGEMENT} component={Management} />
            <Route path={ROUTES.CALENDAR} component={Calendar} />
            <Route path={ROUTES.ANALYTICS} component={Analytics} />
            <Route path={ROUTES.PERMISSIONMANAGEMENT} component={Permission} />
            <Route
              exact
              path={ROUTES.REPOCHATBOT}
              component={SettingsChatBot}
            />
            <Route path={ROUTES.CARDCHATBOT} component={TableCategories} />
            <Route path={ROUTES.CHATBOT} component={ChatBot} />
          </Switch>
        </>
      )}
      {width < 700 && (
        <>
          <MiniDrawer>
            <Chat />
            <Switch>
              <Route
                path={ROUTES.ANNOUNCEMENTPAYLOCITY}
                component={PaylocityAnnouncement}
              />
              <Route
                path={ROUTES.ANNOUNCEMENTBENEFITS}
                component={BenefitsAnnouncement}
              />
              <Route
                path={ROUTES.USERLOCATIONSCHAT}
                component={UserLocationChat}
              />
              <Route exact path={ROUTES.MAPJOBTITLE} component={MapJobtitle} />
              <Route exact path={ROUTES.MYPROFILE} component={MyProfile} />
              <Route path={ROUTES.SPECIALEVENT} component={SpecialEvent} />
              <Route exact path={ROUTES.NEWHOME} component={NewHome} />
              <Route exact path={ROUTES.HOME} component={NewHome} />
              <Route exact path={ROUTES.USER} component={UserValidation} />
              <Route exact path={ROUTES.PROFILE} component={Profile} />
              <Route path={ROUTES.SHORTCUTS} component={Shortcuts} />
              <Route exact path={ROUTES.HOMENEWDESIGN} component={NewHome} />
              <Route
                exact
                path={ROUTES.ANNOUNCEMENTS}
                component={Announcements}
              />
              <Route
                exact
                path={ROUTES.SHORTCUTMANAGEMENT}
                component={ShortcutManagement}
              />
              <Route
                exact
                path={ROUTES.LOCATIONMANAGEMENT}
                component={LocationManagement}
              />
              <Route
                exact
                path={ROUTES.NEWANNOUNCEMENT}
                component={NewAnnouncement}
              />
              <Route
                path={ROUTES.ANNOUNCEMENTCOMPANY}
                component={CompanyAnnouncement}
              />
              <Route
                path={ROUTES.ANNOUNCEMENTLOCAL}
                component={CompanyAnnouncement}
              />
              <Route
                path={ROUTES.ANNOUNCEMENTGROUP}
                component={CompanyAnnouncement}
              />
              <Route
                path={ROUTES.ANNOUNCEMENTOTHERS}
                component={CompanyAnnouncement}
              />
              <Route path={ROUTES.MANAGEMENT} component={Management} />
              <Route path={ROUTES.CALENDAR} component={Calendar} />
              <Route path={ROUTES.ANALYTICS} component={Analytics} />
              <Route
                path={ROUTES.PERMISSIONMANAGEMENT}
                component={Permission}
              />
              <Route path={ROUTES.CHATBOT} component={ChatBot} />
              <Route
                path={ROUTES.SETTINGSCHATBOT}
                component={ChatBotSettings}
              />
              <Route path={ROUTES.REPOCHATBOT} component={SettingsChatBot} />
              <Route path={ROUTES.CARDCHATBOT} component={TableCategories} />
            </Switch>
          </MiniDrawer>
        </>
      )}
    </BrowserRouter>
  );
};

export default withRouter(PrivateRoutes);
